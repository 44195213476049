<template>
	<div>
		<div class="tab-section__content__main">
			<!-- Main Content Start -->
			<div class="hide show@max-tablet">
				<div class="tab-section__content__sidebar--mobile">
					<VButton color="outline-black">
						<!--						Adjust filters:(5)-->
						<VLink
							size="small"
							classes="button--link"
							@click.stop="$emit('onAdjustFilters')"
						>
							Adjust filters:(5)
						</VLink>
					</VButton>
				</div>
			</div>
			<div class="tab-section__content__card">
				<div class="chart-section">
					<div class="chart-section__header gr gr--flex gr--wrap">
						<span class="eng-tab-section__info__item">
							<VLink
								size="small"
								weight="semibold"
								@click.stop.prevent="
									$emit('openAlert', 'What does this visual mean?', 'info')
								"
							>
								<img
									src="@/assets/imgs/icons/icon-info.svg"
									class="question"
									alt="info icon"
								/>
								What does this visual mean?
							</VLink>
						</span>
						<span class="eng-tab-section__info__item">
							<VLink
								size="small"
								weight="semibold"
								@click.stop.prevent="
									$emit(
										'openAlert',
										'Got questions about this visual?',
										'question'
									)
								"
							>
								<img
									src="@/assets/imgs/icons/icon-question.svg"
									class="question"
									alt="question icon"
								/>
								Got questions about this visual?
							</VLink>
						</span>
						<Actions
							classes="button-share chart-section__share"
							@onSaveViewClick="$emit('setActiveModal', 'DataVisualViewModal')"
							@onDownloadClick="
								$emit('setActiveModal', 'CustomizeDownloadModal')
							"
							download
						/>
					</div>

					<slot name="chart" />
				</div>
			</div>
			<!-- Main Content End -->

			<!-- Sidebar Start -->
			<div class="hide show@tablet">
				<div class="tab-section__content__sidebar">
					<VHeading
						level="6"
						weight="semibold"
						classes="tab-section__content__sidebar__title"
					>
						Compare by country
						<img
							src="@/assets/imgs/icons/icon-info.svg"
							class="icon-info"
							alt="info icon"
							@click.stop.prevent="
								$emit('openAlert', 'Compare by country', 'compare-country')
							"
						/>
					</VHeading>

					<div class="tab-section__content__sidebar__filter">
						<slot name="countryFilter" />
					</div>

					<VHeading
						level="6"
						weight="semibold"
						classes="tab-section__content__sidebar__title"
					>
						Compare by indicator
						<img
							src="@/assets/imgs/icons/icon-info.svg"
							class="icon-info"
							alt="info icon"
							@click.stop.prevent="
								$emit('openAlert', 'Compare by indicator', 'compare-indicator')
							"
						/>
					</VHeading>

					<slot name="criteriaFilter" />
				</div>
			</div>
			<!-- Sidebar End -->
		</div>

		<!-- Footer Start -->
		<div class="tab-section__content__footer">
			<VHeading level="6" weight="semibold" classes="hide show@max-tablet">
				Key
			</VHeading>

			<div class="tab-section__content__footer__card footer">
				<VHeading
					level="6"
					weight="semibold"
					classes="hide show@tablet title key"
				>
					Key
				</VHeading>

				<div class="tab-section__content__footer__countries">
					<div
						class="tab-section__content__footer__countries__item"
						v-for="key in keys"
						:key="key.slug"
					>
						<div
							class="flag"
							:style="key.color ? { backgroundColor: key.color } : {}"
						>
							<img
								:src="getImage(key.image)"
								:alt="key.name"
								v-if="key.image"
							/>
						</div>
						<VText size="smaller" weight="semibold">
							{{ key.name }}
						</VText>
					</div>
				</div>
			</div>

			<div class="tab-section__content__footer__sources">
				<VHeading level="6" weight="semibold">
					Data source(s)
				</VHeading>
				<ul>
					<li
						class="bullet"
						v-for="dataSource in dataSources"
						:key="dataSource.id"
					>
						<a :href="dataSource.link" target="_blank" class="xs">
							{{ dataSource.name }}
							<img src="@/assets/imgs/icons/icon-launch.svg" alt="" />
						</a>
					</li>
				</ul>
			</div>
		</div>
		<!-- Footer End -->
	</div>
</template>

<script>
export default {
	props: {
		keys: {
			type: Array,
			required: true
		},
		dataSources: {
			type: Array,
			required: true
		}
	},
	methods: {
		getImage(image) {
			try {
				return require(`@/assets/imgs/${image}`);
			} catch (e) {
				return require(`@/assets/imgs/dummy/circle.png`);
			}
		}
	}
};
</script>