<template>
	<div class="criteria-card__item">
		<div
			class="criteria-card__item__inner"
			@click="$emit('onSetActiveTab', 'sdgs')"
		>
			<div class="p p--semibold">Compare SDG performance</div>
			<div>
				<img
					src="@/assets/imgs/icons/icon-minus.svg"
					key="sdg-minus-icon"
					v-if="activeIndicatorTab.includes('sdgs')"
				/>
				<img
					src="@/assets/imgs/icons/icon-plus.svg"
					key="sdg-plus-icon"
					v-else
				/>
			</div>
		</div>

		<div
			class="criteria-card__content"
			v-if="activeIndicatorTab.includes('sdgs')"
		>
			<div class="criteria-card__content__images">
				<img
					v-for="sdg in sdgs"
					:key="sdg.id"
					:alt="sdg.name"
					:src="require(`@/assets/imgs/sdgs/sm/${sdg.imageSlug}.png`)"
					@click.stop="handleSdgClick(sdg)"
				/>
			</div>
		</div>

		<div class="criteria-card__item__inner">
			<div class="keyword keyword--tooltip" @mouseover="tooltip = true" @mouseleave="tooltip = false" v-if="sdg">
				<span>SDG GOAL {{ sdg.order }}</span>
				<span>
					{{ subSdg ? `(SUB GOAL ${subSdg.order})` : '' }}
				</span>
				<span
					style="margin-left: 10px"
					@click="$emit('onResetSdgs')"
					v-if="padFilters.indicator !== 'sdgs'"
				>
					<img src="@/assets/imgs/icons/icon-close-white.svg" alt=""
				/></span>

				<div class="keyword__tooltip" v-if="tooltip">
					<div class="p p--extra-small p--extra-bold">
						SDG goal {{ sdg.order }}
						{{
							subSdg ? `(sub-goal ${subSdg.order})` : ''
						}}
					</div>
					<div class="p p--extra-small" :title="subSdg ? subSdg.description : sdg.description">
						{{ subSdg
              ? (subSdg.description.length > 100 ? subSdg.description.slice(0, 100) + '...' : subSdg.description)
              : (sdg.description.length  > 100 ? sdg.description.slice(0, 100) + '...' : sdg.description) }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import {
	SET_PAD_SDG,
	SET_ACTIVE_MODAL
} from '@/store/mutations.type';

export default {
	name: 'SDGs',
	props: {
		activeIndicatorTab: String,
		sdg: {
			required: true
		},
		subSdg: {
			required: true
		}
    },
    data() {
        return { 
            tooltip: false
        }
    },
	computed: {
		...mapGetters(['padFilters']),
		...mapState({
			sdgs: state => state.pad.sdgs
		})
	},
	methods: {
		handleSdgClick(sdg) {
			this.$store.commit(SET_PAD_SDG, sdg);
            this.$store.commit(SET_ACTIVE_MODAL, 'SDGModal');
            this.padFilters.indicator = 'sdgs';
		}
	}
};
</script>
