<template>
	<Tab>
		<template #title>Data explorer</template>
		<template #subtitle>
			{{ title }}
		</template>
		<template #content>
			<ChartData
				:keys="keys"
				:data-sources="dataSources"
				@setActiveModal="setActiveModal"
				@onAdjustFilters="setFiltersModal(true)"
				@openAlert="handleOpenAlert"
			>
				<template #chart>
					<div class="toggle-box" v-if="showPerCapitaToggle">
						<label for="show-budget">
							<small key="totals" v-if="padFilters.budgetPerCapita">
								Show budget totals
							</small>
							<small key="per-capita" v-else>
								Show budget per capita
							</small>
							<input
								id="show-budget"
								type="checkbox"
								v-model="padFilters.budgetPerCapita"
							/>
							<span><small></small></span>
						</label>
					</div>

					<component :is="activeChart" />

					<Indicators />
				</template>

				<template #countryFilter>
					<CountriesMultiselect
						:queryParams="countryQueryParams"
						v-model="padFilters.countries"
					/>
				</template>

				<template #criteriaFilter>
					<Filters
						:sdg="padFilters.sdg"
						:sub-sdg="padFilters.subSdg"
						:sector.sync="padFilters.sector"
						@onResetSdgs="handleResetSdgs"
					/>
				</template>
			</ChartData>

			<component
				:is="modal"
				ref="modal"
				:indicator="padFilters.indicator"
				:errors="errors"
				@close="setActiveModal(null)"
				@onSubmit="handleModalSubmit"
				@onPreview="handlePreview"
				v-if="modal"
			/>

			<portal to="pad" v-if="filtersModal">
				<PADFilterModal @close="setFiltersModal(false)" />
			</portal>
		</template>
	</Tab>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import {
	SAVE_VISUAL,
	FETCH_SECTORS,
	FETCH_PAD_SDGS,
	FETCH_PAD_INDICATORS
} from '@/store/actions.type';

import { SET_PAD_TITLE } from '@/store/mutations.type';

import pad from '@/mixins/pad';
import EventBus from '@/eventBus';
import Errors from '@/helpers/Errors';
import tooltipAlert from '@/mixins/tooltip-alert';
import PADFilterModal from '@/modals/PADFilterModal';
import ChartData from '@/pages/LandingPage/layout/ChartData';
import DataVisualViewModal from '@/modals/DataVisualViewModal';
import CustomizeDownloadModal from '@/modals/CustomizeDownloadModal';
import SDGsChart from '@/pages/LandingPage/sections/PolicyAdvocacyDataExplorer/SDGsChart';
import Filters from '@/pages/LandingPage/sections/PolicyAdvocacyDataExplorer/Filters/index';
import Indicators from '@/pages/LandingPage/sections/PolicyAdvocacyDataExplorer/Indicators';
import TotalBudgetChart from '@/pages/LandingPage/sections/PolicyAdvocacyDataExplorer/TotalBudgetChart';
import BudgetBySectorChart from '@/pages/LandingPage/sections/PolicyAdvocacyDataExplorer/BudgetBySectorChart';

export default {
	name: 'PolicyAdvocacyDataExplorer',
	mixins: [pad, tooltipAlert],
	components: {
		Filters,
		SDGsChart,
		ChartData,
		Indicators,
		PADFilterModal,
		TotalBudgetChart,
		BudgetBySectorChart,
		DataVisualViewModal,
		CustomizeDownloadModal
	},
	data() {
		return {
			countryQueryParams: { graph: 'pai' },
			modal: null,
			filtersModal: false,
			errors: new Errors()
		};
	},
	computed: {
		...mapGetters(['padFilters', 'activeSdg']),
		...mapState({
			keys: state => state.pad.keys,
			sdgs: state => state.pad.sdgs,
			title: state => state.pad.title,
			sectors: state => state.app.sectors,
			countries: state => state.app.countries,
			dataSources: state => state.pad.dataSources
		}),
		activeChart() {
			switch (this.padFilters.indicator) {
				case 'total-budget':
					return 'TotalBudgetChart';
				case 'budget-by-sector':
					return 'BudgetBySectorChart';
				case 'sdgs':
					return 'SDGsChart';
			}
		},
		showPerCapitaToggle() {
			const { indicator, sector } = this.padFilters;

			return (
				indicator &&
				(!indicator.includes('sdgs') || (indicator.includes('sdgs') && sector))
			);
		}
	},
	watch: {
		padFilters: {
			handler: function () {
				if (!this.padFilters.indicator) return;
				this.setPadRouterParams(this.padFilters);
				this.fetchChartData(true);
			},
			deep: true
		},
		'padFilters.budgetPerCapita': {
			handler: 'setTitle',
			immediate: true
		},
		'padFilters.sdg': 'setTitle',
		'padFilters.subSdg': 'setTitle',
		'padFilters.sector': 'setTitle',
		'padFilters.indicator': 'setTitle',
		countries: {
			handler: 'setPreselectedCountries',
			immediate: true,
			deep: true
		}
	},
	destroyed() {
		this.padFilters.countries = [];
		this.padFilters.indicator = null;
		this.padFilters.sector = null;
		this.padFilters.subSdg = null;
		this.padFilters.sdg = null;
		this.padFilters.budgetPerCapita = false;
	},
	created() {
		this.handleSdgChange();
	},
	async mounted() {
		await this.fetchData();
		this.setData();
	},
	methods: {
		async handleModalSubmit(form, additional = null) {
			if (this.modal === 'CustomizeDownloadModal') {
				this.submitPadDownloadModalForm(form, additional);
				return;
			}

			this.submitVisualDataModalForm(form);
		},
		handlePreview(form, additional = null) {
			const previewURL = this._generatePadPreviewURL(form, additional);

			window.open(previewURL, '_blank');
		},
		async submitVisualDataModalForm(form) {
			try {
				form = {
					...form,
					type: 'pad',
					...{ filters: this.padFilters }
				};

				await this.$store.dispatch(SAVE_VISUAL, form);

				this.setActiveModal(null);
				this.$toastr.s('Success', 'Successfully saved visual');
			} catch ({ response: { data } }) {
				this.errors.record(data);
			}
		},
		async fetchData() {
			await this.$store.dispatch(FETCH_PAD_SDGS);
			await this.$store.dispatch(FETCH_SECTORS);
			await this.$store.dispatch(FETCH_PAD_INDICATORS);
		},
		setTitle() {
			switch (this.padFilters.indicator) {
				case 'total-budget':
					this.setTotalBudgetTitle();
					break;
				case 'budget-by-sector':
					this.setBudgetBySectorTitle();
					break;
				case 'sdgs':
					this.setSdgsTitle();
					break;
			}
		},
		setTotalBudgetTitle() {
			const { budgetPerCapita } = this.padFilters;

			const title = budgetPerCapita
				? '2020 budget per capita'
				: 'Total 2020 budget';

			this.$store.commit(SET_PAD_TITLE, title);
		},
		setBudgetBySectorTitle() {
			const { budgetPerCapita, sector } = this.padFilters;

			const title = budgetPerCapita
				? `2020 budget ${
						sector ? `for ${sector.name}` : 'by sector'
				  } per capita`
				: `Total 2020 budget ${sector ? `for ${sector.name}` : 'by sector'}`;

			this.$store.commit(SET_PAD_TITLE, title);
		},
		setSdgsTitle() {
			this.$store.commit(SET_PAD_TITLE, 'Budgets vs SDGs');
		},
		setActiveModal(modal) {
			this.modal = modal;

			if (!modal) return;

			this.$nextTick(() => {
				let title = `Data explorer`;
				const { sdg, subSdg, countries, sector } = this.padFilters;

				if (sdg) {
					const sdgsLabel = ` / SDG GOAL ${sdg.order}`;
					title += sdgsLabel;
				}

				if (subSdg) {
					const subSdgLabel = ` (SUB GOAL ${subSdg.order})`;
					title += subSdgLabel;
				}

				const focusCountriesCount = countries.length;
				if (focusCountriesCount) {
					title += ` / ${focusCountriesCount} focus ${
						focusCountriesCount > 1 ? 'countries' : 'country'
					}`;
				}

				if (sector) {
					title += ` / ${sector.name}`;
				}

				this.$refs.modal.form.title = title;
				this.$refs.modal.title = title;
			});
		},
		setPreselectedCountries() {
			if (!this.countries.length) return;

			const { countries } = this.$route.query;

			this.padFilters.countries = [];
			if (countries) {
				const countriesList =
					countries && typeof countries == 'object' ? countries : [countries];

				this.padFilters.countries = this.countries.filter(
					country =>
						countriesList.includes(String(country.id)) ||
						countriesList.includes(country.id)
				);
			}

			if (this.padFilters.countries.length) return;
			this.padFilters.countries = [...this.countries];
		},
		setFiltersModal(value) {
			this.filtersModal = value;
		},
		handleOpenAlert(title, slug) {
			switch (slug) {
				case 'info':
				case 'question':
					this.openInfoAlert(title, `pad-${this.padFilters.indicator}-${slug}`);
					return;
				case 'compare-indicator':
					this.openInfoAlert(title, `pad-${slug}`);
					return;
				default:
					this.openInfoAlert(title, slug);
			}
		},
		handleResetSdgs() {
			this.padFilters.sdg = null;
			this.padFilters.subSdg = null;
		},
		handleSdgChange() {
			EventBus.$on('SET_SDG', (sdg, subSdg) => {
				if (this.filtersModal) return;

				this.padFilters.sdg = sdg;
				this.padFilters.subSdg = subSdg;
			});
		}
	}
};
</script>
