<template>
	<Modal
		classes="modal--pa-index modal--download"
		class="modal--download"
		@close="$emit('close')"
	>
		<template #header>
			<VHeading level="2">Customise your download</VHeading>
		</template>
		<template #content>
			<div>
				<VText size="big">
					Download data visuals tailored to your requirements:
				</VText>
				<div class="form">
					<div class="form__inner">
						<VText size="big" classes="title" weight="bold">
							Format:
						</VText>

						<div class="content">
							<VDefaultField :error="errors.get('format')">
								<template #field>
									<div
										class="form__group__inner"
										v-for="format in formats"
										:key="format.value"
									>
										<VRadio
											:id="format.value"
											:label="format.name"
											:input-value="format.value"
											v-model="form.format"
											@input="errors.clear('format')"
										/>
									</div>
								</template>
							</VDefaultField>
						</div>
					</div>

					<div class="form__inner" v-if="form.format !== 'csv'">
						<VText size="big" classes="title" weight="bold">
							Items to include:
						</VText>

						<div class="content">
							<VDefaultField :error="errors.get('value')">
								<template #field>
									<div
										class="form__group__inner"
										v-for="item in items"
										:key="item.value"
									>
										<input
											type="checkbox"
											class="form__checkbox"
											:id="item.value"
											:value="item.value"
											v-model="form.items"
											@input="errors.clear('value')"
										/>
										<VLabel :for="item.value">
											{{ item.name }}
										</VLabel>
									</div>
								</template>
							</VDefaultField>
						</div>
					</div>
					<div class="form__inner">
						<VText size="big" classes="title" weight="bold">
							Title for the data visual:
						</VText>
						<VText size="small" classes="subtitle">
							You can optionally specify your own title
						</VText>

						<VDefaultField :error="errors.get('title')">
							<template #field>
								<VInput
									type="text"
									placeholder="Title"
									v-model="form.title"
									@input="errors.clear('title')"
								/>
							</template>
						</VDefaultField>

						<VLink
							size="small"
							align="right"
							@click.stop.prevent="resetTitle"
							v-if="title != form.title"
						>
							Reset to original title
						</VLink>

						<VDefaultField
							v-if="indicator !== 'composite-score' && indicator !== 'sdgs'"
						>
							<template #field>
								<div
									class="form__group__inner"
								>
									<input
											type="checkbox"
											class="form__checkbox"
											id="changeColors"
											:value="form.changeColors"
											v-model="form.changeColors"
									/>
									<VLabel for="changeColors">
										Change Colors
									</VLabel>
								</div>
							</template>
						</VDefaultField>

						<div
							v-if="form.changeColors"
							class="flex"
						>
							<div
									class="form__group__inner flex"
									v-for="(item, index) in colors"
									:key="`color-${index}`"
							>
								<div class="flag" :style="'background-color:' + item"><!----></div><div style="margin-right: 2px">:</div>
								<input
										type="color"
										:id="'color' + index"
										:value="form.colors[index]"
										@input="handleColorChange($event, index)"
								/>
							</div>
						</div>

						<VLink
								size="small"
								align="right"
								@click.stop.prevent="resetTitle"
								@click="$emit('onPreview', cleanForm, form)"
						>
							Preview
						</VLink>
					</div>
				</div>
			</div>
		</template>
		<template #footer>
			<VButton
				color="primary"
				:disabled="loading"
				@click="$emit('onSubmit', cleanForm, form)"
			>
				{{ loading ? 'Downloading...' : 'Download now' }}
			</VButton>
		</template>
	</Modal>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
	name: 'CustomizeDownloadModal',
	props: {
		errors: {},
		indicator: {}
	},
	data() {
		return {
			title: null,
			form: {
				format: 'pdf',
				items: ['url'],
				title: 'Some generated title',
				changeColors: false,
				colors: []
			},
			formats: [
				{ name: 'PDF', value: 'pdf' },
				{ name: 'PNG', value: 'png' },
				{ name: 'CSV', value: 'csv' }
			],
			items: [
				{ name: 'URL', value: 'url' },
				{ name: 'Legend', value: 'legend' },
				{ name: 'Data visual title', value: 'data-visual-title' },
				{ name: 'Text explanation for visual', value: 'text-explanation' }
			],
			colors: ['#87bbaf']
		};
	},
	computed: {
		...mapGetters(['loading']),
		...mapState({
			padKeys: store => store.pad.keys
		}),
		cleanForm() {
			const form = {};

			if (this.form.items.includes('data-visual-title')) {
				form.title = this.form.title;
			}

			if (this.form.items.includes('legend')) {
				form.legend = true;
			}

			if (this.form.items.includes('text-explanation')) {
				form.explanation = true;
			}

			if (this.form.items.includes('url')) {
				form.url = true;
			}

			return form;
		}
	},
	created() {
		this.setData();
	},
	watch: {
		'form.changeColors': 'setData'
	},
	methods: {
		setData() {
			if (this.indicator === 'budget-by-sector' || this.indicator === 'total-budget') {
				this.colors = this.padKeys.map(key => {
					return key.color;
				}).filter(color => color);
			}

			this.form.colors = [...this.colors];
		},
		resetTitle() {
			this.form.title = this.title;
		},
		handleColorChange({ target: { value }}, index) {
			this.form.colors[index] = value;
		}
	}
};
</script>

<style scoped>
.form__label {
	padding-left: 0 !important;
}
.flag {
	border-radius: 50%;
	width: 20px;
	height: 20px;
	margin-left: 20px;
	margin-right: 5px;
}
</style>
