<template>
	<div class="criteria-card__item">
		<div
			class="criteria-card__item__inner"
			@click="$emit('onSetActiveTab', 'sector')"
		>
			<div>
				<div class="p p--semibold">Budget by sector</div>

				<div
					class="keyword"
					v-if="!activeIndicatorTab.includes('sector') && sector"
				>
					{{ sector.name }}
				</div>
			</div>

			<div>
				<img
					src="@/assets/imgs/icons/icon-minus.svg"
					key="sector-minus-icon"
					v-if="activeIndicatorTab.includes('sector')"
				/>
				<img
					src="@/assets/imgs/icons/icon-plus.svg"
					key="sector-plus-icon"
					v-else
				/>
			</div>
		</div>

		<div
			class="criteria-card__content"
			v-if="activeIndicatorTab.includes('sector')"
			@click="handleSectorClicked"
		>
			<VText
				size="small"
				:classes="
					`criteria-card__content__option cursor-pointer ${
						!sector ? 'active' : ''
					}`
				"
				v-bind="!sector ? { weight: 'bold' } : {}"
				@click="$emit('update:sector', null)"
			>
				{{
					padFilters.indicator && padFilters.indicator.includes('sdgs')
						? 'None'
						: 'Total budget'
				}}
			</VText>

			<div class="criteria-card__content__others">
				<VText
					v-for="sector in filteredSectors"
					:key="sector.slug"
					size="small"
					:classes="
						`criteria-card__content__option cursor-pointer ${
							isSectorActive(sector) ? 'active' : ''
						}`
					"
					v-bind="isSectorActive(sector) ? { weight: 'bold' } : {}"
					@click="$emit('update:sector', sector)"
				>
					{{ sector.name }}
				</VText>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
	props: {
		activeIndicatorTab: String,
		sector: {
			required: true
		}
	},
	computed: {
		...mapGetters(['padFilters']),
		...mapState({
			sectors: state => state.app.sectors
		}),
		filteredSectors() {
			return this.sectors.filter(
				sector => !sector.slug.includes('Cross Sector')
			);
		}
	},
	methods: {
		isSectorActive(sector) {
			return this.sector && this.sector.slug === sector.slug;
		},
		handleSectorClicked() {
			if (this.padFilters.indicator.includes('sdgs')) return;

			this.padFilters.indicator = 'budget-by-sector';
		}
	}
};
</script>
