<template>
	<div class="tab-section__content__card">
		<div class="chart-section__tabs">
			<VText
				v-for="indicator in indicators"
				size="extra-small"
				:key="indicator.id"
				:classes="
					`chart-section__tabs__item cursor-pointer ${
						padFilters.indicator === indicator.slug ? 'active' : ''
					}`
				"
				@click="padFilters.indicator = indicator.slug"
			>
				{{ indicator.name }}
			</VText>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
	computed: {
		...mapGetters(['padFilters']),
		...mapState({
			indicators: state => state.pad.indicators
		})
	}
};
</script>
<style scoped>
	@media only screen and (max-width: 991px) {
		.tab-section__content__card {
			padding: 28px 0 18px;
		}
	}

</style>
