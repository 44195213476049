<template>
	<SmFilters
		@onSubmit="applyFilters"
		@onInfoClick="handleOpenInfoAlert"
		@close="$emit('close')"
	>
		<template #countries>
			<CountriesMultiselect
				:queryParams="countryQueryParams"
				v-model="filters.countries"
			/>
		</template>
		<template #indicators>
			<Filters
				:sector.sync="filters.sector"
				:sdg="filters.sdg"
				:sub-sdg="filters.subSdg"
			/>
		</template>
	</SmFilters>
</template>

<script>
import { mapGetters } from 'vuex';

import EventBus from '@/eventBus';
import tooltipAlert from '@/mixins/tooltip-alert';
import Filters from '@/pages/LandingPage/sections/PolicyAdvocacyDataExplorer/Filters/index';

export default {
	name: 'PADFilterModal',
	mixins: [tooltipAlert],
	components: {
		Filters
	},
	data() {
		return {
			filters: {
				countries: [],
				sector: null,
				sdg: null,
				subSdg: null
			}
		};
	},
	created() {
		this.handleSdgChange();
		this.setFilters();
	},
	computed: {
		...mapGetters(['padFilters']),
		countryQueryParams() {
			return {
				graph: 'pad',
				is_active: 'true',
				indicator: this.padFilters.indicator,
				sdg: this.padFilters.sdg ? this.padFilters.sdg.slug : '',
				subSdg: this.padFilters.subSdg ? this.padFilters.subSdg.slug : '',
				sector: this.padFilters.sector ? this.padFilters.sector.slug : ''
			};
		}
	},
	methods: {
		setFilters() {
			this.filters.countries = this.padFilters.countries;
			this.filters.sector = this.padFilters.sector;
			this.filters.subSdg = this.padFilters.subSdg;
			this.filters.sdg = this.padFilters.sdg;
		},
		applyFilters() {
			this.padFilters.countries = this.filters.countries;
			this.padFilters.subSdg = this.filters.subSdg;
			this.padFilters.sector = this.filters.sector;
			this.padFilters.sdg = this.filters.sdg;

			this.$emit('close');
		},
		handleSdgChange() {
			EventBus.$on('SET_SDG', (sdg, subSdg) => {
				this.filters.sdg = sdg;
				this.filters.subSdg = subSdg;
			});
		},
		handleOpenInfoAlert(title, indicator) {
			indicator = indicator.includes('indicator')
				? `pad-${indicator}`
				: indicator;

			this.openInfoAlert(title, indicator);
		}
	}
};
</script>

<style scoped>
.navbar__filters__content {
	margin-top: 17px;
}

/deep/.criteria-card__content__images {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}
</style>
