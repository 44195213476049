<template>
	<div class="criteria-card">
		<Sector
			:sector="sector"
			:activeIndicatorTab="activeIndicatorTab"
			@onSetActiveTab="setActiveTab"
			@update:sector="handleSectorUpdate"
		/>
		<SDGs
			:sdg="sdg"
			:subSdg="subSdg"
			:activeIndicatorTab="activeIndicatorTab"
			@onSetActiveTab="setActiveTab"
			@onResetSdgs="$emit('onResetSdgs')"
		/>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import Sector from '@/pages/LandingPage/sections/PolicyAdvocacyDataExplorer/Filters/Sector';
import SDGs from '@/pages/LandingPage/sections/PolicyAdvocacyDataExplorer/Filters/SDGs';

export default {
	name: 'Filters',
	props: {
		sector: {
			required: true
		},
		sdg: {
			required: true
		},
		subSdg: {
			required: true
		}
	},
	components: {
		Sector,
		SDGs
	},
	data() {
		return {
			activeIndicatorTab: 'sector'
		};
	},
	methods: {
		setActiveTab(activeIndicatorTab) {
			if (this.activeIndicatorTab === activeIndicatorTab) {
				this.activeIndicatorTab = '';
				return;
			}

			this.activeIndicatorTab = activeIndicatorTab;
		},
		handleSectorUpdate(value) {
			this.$emit('update:sector', value);
		}
	}
};
</script>
